import styled from 'styled-components';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';
import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

export const StyledWrapper = styled.div<{ isWhite?: boolean }>`
  background-color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.white : theme.colors.complementary1};
  padding-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    padding-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.wrapperXxl};
  margin-inline: auto;
  position: relative;

  ${breakpoint.lg} {
    min-height: 632px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledContent = styled.div<{ isPaddingText?: boolean }>`
  max-width: ${({ theme }) => theme.wrapper};
  margin-inline: auto;
  display: grid;
  gap: ${({ theme }) => theme.gap.mdl};
  grid-template-rows: max-content 1fr;

  ${breakpoint.xl} {
    gap: unset;

    padding-left: ${({ isPaddingText }) => (isPaddingText ? `50px` : `0`)};
  }
`;

export const StyledBreadcrumbWrapper = styled.div<{ isWhiteBreadcrumb?: boolean }>`
  padding-inline: ${({ theme }) => theme.gap.sm};

  & .breadcrumb__separator {
    color: ${({ theme, isWhiteBreadcrumb }) =>
      isWhiteBreadcrumb ? theme.colors.white : theme.colors.gray40};
  }

  & .breadcrumb__list__item a {
    color: ${({ theme, isWhiteBreadcrumb }) =>
      isWhiteBreadcrumb ? theme.colors.white : theme.colors.gray100};
  }

  & .breadcrumb__list__item a[aria-current='page'] {
    color: ${({ theme, isWhiteBreadcrumb }) =>
      isWhiteBreadcrumb ? theme.colors.whiteAlpha40 : theme.colors.gray60};
  }

  ${breakpoint.lg} {
    grid-column: 1 / 3;
    padding-inline: 0;
  }
`;

export const StyledSection = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.gap.sm};
  place-content: center;
  max-width: 444px;
  padding-inline: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    gap: ${({ theme }) => theme.gap.md};
    padding-inline: 0;
    padding-block: 136px 200px;
  }
`;

export const StyledTitle = styled(Typography)`
  word-break: keep-all;
`;

export const StyledDescription = styled(Typography)`
  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;

    ${breakpoint.lg} {
      font-size: ${({ theme }) => theme.gap.md};
      line-height: 1.25;
    }
  }
`;

export const StyledWpImage = styled(WpImage)`
  min-width: 344px;
  min-height: 256px;
  aspect-ratio: 344 / 328;

  max-height: 306px;
  justify-self: end;

  ${breakpoint.xl} {
    max-width: 692px;
    max-height: 489px;
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledImageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.complementary1};
  position: relative;
  width: 100%;
  display: grid;
  margin-top: ${({ theme }) => theme.gap.mdl};

  ${breakpoint.lg} {
    translate: 0 82px;
    background-color: transparent;
    margin-top: 0;
    padding-left: 0;
    position: absolute;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: ${({ theme }) => theme.colors.white};

    ${breakpoint.lg} {
      display: none;
    }
  }
`;

export const StyledDotsGridOne = styled(DotsGrid)`
  position: absolute;
  bottom: ${({ theme }) => theme.gap.sm};
  right: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    top: 50%;
    right: 0;
  }
`;

export const StyledDotsGridTwo = styled(DotsGrid)`
  display: none;

  ${breakpoint.lg} {
    display: block;
    position: absolute;
    left: ${({ theme }) => theme.gap.md};
    bottom: -204px;
  }

  ${breakpoint.xl} {
    left: ${({ theme }) => theme.gap.md};
    bottom: -184px;
  }
`;
